import { NetAdapter } from "./NetAdapter";
import React, { ReactNode } from "react";
import { All } from "@isntfunny/hackacloud-shared";
import '../less/components/ItemList.scss';
import { Sensor } from "./Sensor";

interface Props {
    data: All;
    children?: ReactNode;
}

export const ItemList = ({children, data}: Props) => {
    return (
        <>
            {Object.keys(data.netif).map(key =>
                <NetAdapter key={key} config={data.config} netif={data.netif[key]} name={key}/>
            )}
            {Object.keys(data.sensors).map(key =>
                <Sensor key={key} value={data.sensors[key]} name={key}/>
            )}
            {children}
        </>
    );
};