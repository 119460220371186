import React, { useEffect, useState } from 'react';
import { All } from '@isntfunny/hackacloud-shared';
import { ItemList } from "./components/ItemList";
import { MainChart } from "./components/MainChart";
import { useApi } from "./ApiContext";
import { LiveButton } from "./components/LiveButton";
import { ReactNotifications } from "react-notifications-component";
import { Store } from 'react-notifications-component';
import { NOTIFICATION_TYPE } from "react-notifications-component/dist/src/typings";
import 'react-notifications-component/dist/scss/notification.scss';
import useDeepCompareEffect from "./utils/useDeepCompareEffect";
import { BitrateSelector } from "./components/BitrateSelector";
import usePipelines from "./utils/usePipelines";
import { SimpleCard } from "./components/SimpleCard";

const statusMapper: { [index: string]: NOTIFICATION_TYPE } = {
    'error': 'danger',
    'warning': 'warning',
    'default': 'info'
}

function App() {
    const api = useApi();
    const [data, setData] = useState<All>();
    const [activeSSE, setActiveSSE] = useState(false);
    const [notifications, setNotifications] = useState<string[]>([]);

    const { data: pipelines } = usePipelines();

    const launchSSE = () => {
        const eventSource = new EventSource(api.url + '/events/' + api.key);

        eventSource.onopen = () => {
            setActiveSSE(true);
        }

        eventSource.onmessage = (event) => {
            const all = JSON.parse(event.data);
            setData(all);
        };

        eventSource.onerror = () => {
            setActiveSSE(false);
            eventSource.close();

            console.warn('error, trying to reconnect')
            setTimeout(launchSSE, 500);
        };

        return eventSource;
    }

    useEffect(() => {
        if (!activeSSE) {
            Store.addNotification({
                id: 'off',
                title: 'Offline',
                message: 'No device connected',
                type: 'danger',
                insert: "top",
                container: "top-center",
            });
        } else {
            Store.removeNotification('off');
        }
    }, [activeSSE]);

    useEffect(() => {
        const eventSource = launchSSE();

        return () => {
            console.log('disconnected');
            setActiveSSE(false);
            eventSource.close();
        };
    }, []);

    useDeepCompareEffect(() => {
        if (!data) {
            return;
        }

        for (const notify of notifications) {
            if (!data.notifications.show.some(obj => obj.name === notify)) {
                Store.removeNotification(notify);
                setNotifications(notifications.filter(str => str !== notify))
            }
        }

        console.log('notifications', notifications);

        for (const notification of data.notifications.show) {
            if (!notifications.includes(notification.name)) {
                setNotifications([...notifications, notification.name]);
                Store.addNotification({
                    id: notification.name,
                    title: notification.name,
                    message: notification.msg,
                    type: statusMapper[notification.type] || statusMapper['default'],
                    insert: "top",
                    container: "top-center",
                });
            }
        }
    }, [data?.notifications]);

    return (
        <div className={"App " + (data?.status.is_streaming ? 'running' : 'error')}>
            <ReactNotifications />
            <header className="App-header">
                <div>{!activeSSE ? <div className={"warning"}>DERZEIT KEINE VERBINDUNG</div> : ''}</div>
                {data && <MainChart netif={data.netif} config={data.config} status={data.status} />}
            </header>
            {data ?
                <main>
                    <BitrateSelector config={data.config} />
                    <LiveButton status={data.status}></LiveButton>
                    <div id={"item-list"}>
                        <ItemList data={data}>
                            {pipelines ? <SimpleCard title={'Aktive Pipeline'}
                                subtitle={pipelines[data.config.pipeline].name} /> : ''}
                        </ItemList>
                    </div>
                </main>
                : ''}
        </div>
    );
}

export default App;
