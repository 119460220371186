import { useQuery } from "@tanstack/react-query";
import { useApi } from "../ApiContext";
import Pipeline from "@isntfunny/hackacloud-shared/types/Pipeline";

export default function usePipelines() {
    const api = useApi();

    return useQuery<{[index: string]: Pipeline}>({
        queryKey: [ 'posts' ],
        queryFn: async () => {
            return (await api.all()).pipelines
        },
    });
}