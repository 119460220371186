import { Card, CardContent } from "@mui/material";

interface Props {
    title: string;
    subtitle: string;
    color?: string;
}

export const SimpleCard = ({title, subtitle, color}: Props) => {
    return (
        <Card className={'device'}
              style={{backgroundColor: color ?? 'rgba(255,255,255)'}}>
            <CardContent>
                <h2>{title}</h2>
                <div>{subtitle}</div>
            </CardContent>
        </Card>
    );
};