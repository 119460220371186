import { Config, Netif, Status } from "@isntfunny/hackacloud-shared";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import '../less/components/MainChart.scss';
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { useEffect, useRef, useState } from "react";
import SmoothCounter from "../utils/SmoothCounter";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

interface Props {
    netif: { [index: string]: Netif },
    config: Config;
    status: Status;
}

export const MainChart = ({netif, config, status}: Props) => {
    const summaryTp = Object.values(netif).reduce((acc, item) => acc + item.tp, 0);
    const targetValue = Math.round(summaryTp * 8 / 1024);
    const [ displayValue, setDisplayValue ] = useState(0);
    const counterRef = useRef<SmoothCounter>();

    useEffect(() => {
        if (!counterRef.current) {
            counterRef.current = new SmoothCounter(0, setDisplayValue);
        }
        return () => {
            if (counterRef.current && counterRef.current.requestId) {
                cancelAnimationFrame(counterRef.current.requestId);
            }
        };
    }, []);

    useEffect(() => {
        if (counterRef.current) {
            counterRef.current.setValue(targetValue);
        }
    }, [ targetValue ]);


    //<GaugeChart bitrate={Math.round(summaryTp * 8 / 1024)} maxBitrate={config.max_br} label={status.is_streaming ? '{y}<br />Kbps' : 'OFFLINE'} />
    return (
        <div id={"main-chart"}>
            <Gauge
                value={Math.round(displayValue)}
                valueMin={0}
                valueMax={config.max_br + 2000}
                startAngle={-110}
                endAngle={110}
                sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 40,
                        transform: 'translate(0px, 0px)',
                    },
                }}

                text={({value}) => status.is_streaming ? `${value} Kbps` : 'OFFLINE'}
            />
        </div>
    );
};