import { ListItem } from "./ListItem";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

interface Props {
    value: string;
    name: string;
}

export const Sensor = ({value, name}: Props) => {
    const temp = Number.parseFloat(value.replace(' °C', ''));

    return (
        <ListItem value={temp}
                  maxValue={50}
                  unit={'°C'}
                  title={name}
                  enabled={true}
                  inverted={true}
        >
            <div style={{width: '80px', height: '80px'}}>
                <Gauge
                    value={temp}
                    valueMin={0}
                    valueMax={60}
                    startAngle={-110}
                    endAngle={110}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 40,
                            transform: 'translate(0px, 0px)',
                        },
                    }}
                    text=' '
                />
            </div>
        </ListItem>
    );
};