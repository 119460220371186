import { MouseEvent, ReactNode } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

interface Props {
    title: string;
    value: number;
    unit: string;
    maxValue: number;
    subtitle?: string;
    enabled: boolean;
    inverted?: boolean;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    children?: ReactNode;
}

export const ListItem = ({children, value, unit, maxValue, title, subtitle, inverted, enabled, onClick}: Props) => {
    const percentage = (value / maxValue) * 100;
    const backgroundColor = (percent: number): string => {
        const r = percent < 50 ? 255 : Math.round(255 * (1 - (percent - 50) / 50));
        const g = percent > 50 ? 255 : Math.round(255 * (percent / 50));
        const b = 0;
        return `rgb(${r}, ${g}, ${b})`;
    }

    const bg = enabled
        ? backgroundColor(inverted ? 100 - percentage : percentage)
        : 'rgb(217,217,217)';

    return (
        <Card className={'device'}
              onClick={onClick}
              style={{backgroundColor: bg, position: 'relative'}}>
            <CardHeader avatar={<>{children}</>} style={{padding: 0}} />
            <CardContent>
                <h2>{title}</h2>
                {subtitle ? <div>{subtitle}</div> : ''}
                <div>{value.toLocaleString()} {unit}</div>
            </CardContent>
        </Card>
    );
};