export default class SmoothCounter {
    requestId?: number;

    private targetValue: number;
    private displayValue: number;
    private readonly updateCallback: (value: number) => void;

    constructor(initialValue: number, updateCallback: (value: number) => void) {
        this.targetValue = initialValue;
        this.displayValue = initialValue;
        this.updateCallback = updateCallback;
        this.animate = this.animate.bind(this);
    }

    private animate() {
        const difference = this.targetValue - this.displayValue;
        this.displayValue += difference * 0.05;

        if (this.updateCallback) {
            this.updateCallback(this.displayValue);
        }

        if (Math.abs(difference) > 0.1) {
            this.requestId = requestAnimationFrame(this.animate);
        } else if (this.requestId) {
            cancelAnimationFrame(this.requestId);
        }
    }

    public setValue(newValue: number) {
        if (this.requestId) {
            cancelAnimationFrame(this.requestId);
        }

        this.targetValue = newValue;
        this.requestId = requestAnimationFrame(this.animate);
    }

    public getDisplayValue() {
        return this.displayValue;
    }
}