import React, { useEffect } from "react";
import { useApi } from "../ApiContext";
import { Status } from "@isntfunny/hackacloud-shared";
import '../less/components/LiveButton.scss';
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface Props {
    status: Status;
}

export const LiveButton = ({ status }: Props) => {
    const api = useApi();
    const [open, setOpen] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const controlStream = async () => {
        setBackdrop(true);
        setOpen(false);

        if (status.is_streaming) {
            api.stop();
        } else {
            api.start();
        }
    }

    useEffect(() => {
        setBackdrop(false);
    }, [status]);

    return (
        <>
            <Button
                style={{ width: '100%' }}
                variant="contained"
                size={"large"}
                color={status.is_streaming ? "primary" : "secondary"}
                onClick={handleOpen}
            >{status.is_streaming ? "STOP STREAM" : "GO LIVE"}</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change Stream Status"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Bist du dir sicher, dass du den Stream {status.is_streaming ? 'stoppen' : 'starten'} möchtest?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={controlStream}>Ok</Button>
                    <Button onClick={handleClose} autoFocus>
                        Abbrechen
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};