import React, { createContext, useContext, useState, ReactNode } from 'react';
import Api from "./utils/Api";


interface ApiProviderProps {
    apiKey: string;
    children: ReactNode;
}

const ApiContext = createContext<Api | undefined>(undefined);

export const ApiProvider = ({apiKey, children}: ApiProviderProps) => {
    const [ api ] = useState(new Api(apiKey));

    return (
        <ApiContext.Provider value={api}>
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useApi must be used within an ApiProvider');
    }
    return context;
};