import { Config, Netif } from "@isntfunny/hackacloud-shared";
import { ListItem } from "./ListItem";
import { useApi } from "../ApiContext";
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import useDeepCompareEffect from "../utils/useDeepCompareEffect";

interface Props {
    config: Config;
    netif: Netif;
    name: string;
}

export const NetAdapter = ({ netif, name, config }: Props) => {
    const api = useApi();
    const bitrate = Math.round(netif.tp * 8 / 1024);
    const [open, setOpen] = useState(false);
    const [enabled, setEnabled] = useState(netif.enabled);

    useDeepCompareEffect(() => {
        setOpen(false);
        setEnabled(!enabled);
    }, [netif.enabled]);

    // <GaugeChart bitrate={bitrate} maxBitrate={config.max_br} label={''}/>
    return (
        <ListItem
            onClick={event => {
                setOpen(true);
                setTimeout(() => {setOpen(false)}, 2000);
                api.setInterface(name, !netif.enabled)
            }}
            title={name.toUpperCase()}
            value={bitrate} unit={'Kbps'}
            maxValue={config.max_br}
            subtitle={netif.ip}
            enabled={netif.enabled}
        >
            <div style={{ width: '80px', height: '80px' }}>
                <Gauge
                    value={bitrate}
                    valueMin={0}
                    valueMax={config.max_br}
                    startAngle={-110}
                    endAngle={110}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 40,
                            transform: 'translate(0px, 0px)',
                        },
                    }}
                    text=' '
                />
            </div>
            <Backdrop
                sx={{ color: '#fff', position: 'absolute' }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </ListItem>
    );
};