import { useEffect, useRef } from 'react';

export default function useDeepCompareEffect(effect: () => void, dependencies: any[]) {
    const previousDependenciesRef = useRef<any[]>();

    useEffect(() => {
        const isSame = previousDependenciesRef.current?.every((dep, index) => {
            return JSON.stringify(dep) === JSON.stringify(dependencies[index]);
        });

        if (!isSame) {
            previousDependenciesRef.current = dependencies;
            effect();
        }
    }, [ dependencies ]);
}