export default class Api {
    public readonly url = 'https://bela.isntfunny.de';
    key: string;

    constructor(key: string) {
        this.key = key;
    }

    getUrl(path: string) {
        let url = [this.url, this.key];

        path && url.push(path)
        return url.join('/');
    }

    private async caller(path: string, data?: string[]) {
        path = this.getUrl(path);

        if (data) {
            path = [path, ...data].join('/');
        }

        const response = await fetch(path);

        return await response.json();
    }

    /**************************************************/

    async start() {
        return await this.caller('start');
    }

    async stop() {
        return await this.caller('stop');
    }

    async all() {
        return await this.caller('all');
    }

    async setBitrate(bitrate: string) {
        return await this.caller('bitrate', [bitrate]);
    }

    async setInterface(ifaceName: string, enabled: boolean) {
        return await this.caller('setNetif', [ifaceName, enabled ? '1' : '0'])
    }

    async config() {
        return await this.caller('config');
    }
}
