import React from "react";
import { Config } from "@isntfunny/hackacloud-shared";
import { Slider } from "@mui/material";
import { useApi } from "../ApiContext";

interface Props {
    config: Config;
}

export const BitrateSelector = ({config}: Props) => {
    const api = useApi();

    const onChange = (val: number|number[]) => {
        if (Array.isArray(val)) {
            return;
        }

        console.log(val);
        api.setBitrate(val.toString());
    }

    return (
        <Slider
            onChangeCommitted={(e, val) => onChange(val)}
            aria-label="Bitrate"
            valueLabelDisplay="auto"
            value={config.max_br}
            shiftStep={30}
            step={500}
            marks
            min={1000}
            max={12000}
        />
    );
};